/*************************************

    CUSTOM FORM
    = datepicker
    = clockpicker
    = booking-form

*************************************/

textarea {
  resize: none;
}

input[type='checkbox'],
input[type='radio'],
label {
  cursor: pointer;
}

.label-control {
  display: inline-block;
  font-size: 14rem/$font-size-base;
}

.label-warn {
  color: $brand-warning;
}

.form-control {
  padding: 11px 20px 10px;
}

.row-sm {
  margin: 0 -10px;

  > *[class*="col"] {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.form-group {
  margin-bottom: 20px;
}

.input-daterange {
  width: auto;

  input {
    text-align: left;
  }
}

[type=submit] {
  padding-left: 65px;
  padding-right: 65px;
}

.datepicker,
.clockpicker .form-control {
  font-family: $lato;
  letter-spacing: $ls;
  border-radius: $border-radius-base;
}

.select-reserved {
  select {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  .br-widget {
    a {
      display: inline-block;
      width: 13px;
      height: 37px;
      margin-right: 8px;
      user-select: none;
      cursor: pointer;
      transition: none;
      background: url($images + '/man.svg') no-repeat;
    }

    $i: -100%/2; // Number of Icons minus 1
    .br-active,
    .br-selected,
    .br-current {
      background-position: 50% -40px;
    }
  }

  .br-wrapper {
    position: relative;
  }

  .br-current-rating {
    @extend %text-brand-lg;
    float: right;
    margin-top: 3px;
    font-size: 30rem/$font-size-base;
    line-height: 1.1;
    letter-spacing: normal;
  }

  &__check {
    display: none;
    margin: 0;

    + label {
      margin: 0;
      font-size: 14px;
      white-space: nowrap;
      user-select: none;

      > * {
        display: inline-block;
        vertical-align: middle;
      }

      .fa {
        width: 14px;
        height: 14px;
        margin-right: 8px;
        background-color: #fff;
        border: 1px solid $border-light;
        color: $brand-warning;
        border-radius: 3px;
        cursor: pointer;

        &:before {
          opacity: 0
        }
      }

      .span {
        max-width: 130px;
        line-height: 20px;
        font-family: $font-family-serif;
        font-size: 14px;
        color: #000;
        font-weight: 400;
        text-transform: none;
        letter-spacing: normal;
        white-space: normal;
      }

      &:hover .span,
      &:focus .span {
        color: $gray-base;
      }
    }

    &:checked + label .fa:before {
      opacity: 1;
    }
  }

  .disable {
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
    }
    a {
      opacity: .4;
    }
  }

  @include media('<sm') {
    .br-wrapper {
      text-align: right;
    }
  }

  @include media('<xs') {
    .br-current-rating {
      display: block;
      float: none;
      margin-top: 10px;
      margin-bottom: -10px;
      font-size: 37px;
    }

    .br-wrapper,
    .br-current-rating {
      text-align: center;
    }

    &__check {
      + label {
        width: 100%;
        max-width: 152px;
        margin: 0 auto;
      }
    }
  }
}

.datepicker table tr td,
.popover {
  font-family: $lato;
}

/**        = datepicker         **/
.datepicker {
  font-family: $lato;
  text-transform: uppercase;

  table {
    letter-spacing: normal;
  }

  table > thead {
    font-weight: 700;
    border-bottom: 1px solid $border-light;

    > tr + tr > th {
      background-color: #fff;
      &:hover {
        background-color: #fff;
        color: $gray-base;
      }
    }
  }

  .prev, .next {
    position: relative;
    font-family: FontAwesome;
    text-indent: -9999px;
    color: $brand-warning;

    &:after {
      text-indent: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover:after,
    &:focus:after {
      color: $gray-dark;
    }

    &:active:after {
      color: $gray-light;
    }
  }

  .prev:after {
    content: "\f104";
  }

  .next:after {
    content: "\f105";
  }
}


/**        =clockpicker         **/
.popover {
  padding: 0;
  border-radius: 2px;
  border-color: transparent;

  &-content {
    background-color: #fafafa;
  }

  .clockpicker-button {
    @extend .btn--warn;
    border-width: 0;
    border-radius: 0;
  }

  .clockpicker-plate {
    border-color: $brand-warning;
  }

  .popover-title {
    color: $brand-warning;
  }

  .popover-title span {
    color: $brand-warning;
  }
}

.clockpicker-tick.active,
.clockpicker-tick:hover {
  background-color: rgba($brand-warning, .25);
}

.clockpicker-canvas-bg-trans,
.clockpicker-canvas-bg {
  fill: rgba($brand-warning, .25);
}

.clockpicker-canvas-bearing, .clockpicker-canvas-fg {
  fill: $brand-warning;
}

.clockpicker-canvas line,
.clockpicker-canvas-bearing, .clockpicker-canvas-fg {
  stroke: $brand-warning;
}
