/*************************************

    Mobile menu Slideout.js

************************************/

$triggerColor: $gray-dark;
$menuMobileWidth: 256px;

.menu-trigger {
  order: 1;
  position: relative;
  float: right;
  width: 40px;
  height: 30px;
  cursor: pointer;

  &:before {
    position: absolute;
    top: 13px;
    left: 0;
    width: 100%;
    height: 4px;
    background: $triggerColor;
    box-shadow: 0 6px transparent, 0 -8px $triggerColor, 0 18px transparent, 0 8px $triggerColor;
    content: '';
    transition: all .31s ease;
  }

  &:hover:before {
    box-shadow: 0 6px transparent, 0 -12px $triggerColor, 0 18px transparent, 0 12px $triggerColor;
  }
}

.slideout-menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: $menuMobileWidth;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: none;
}

.slideout-panel {
  position:relative;
  z-index: 1;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}

.navbar,
.page-footer {
  transition: all .3s ease;

  &.open {
    transform: translate3d($menuMobileWidth, 0, 0);
  }
}

.slideout-open .slideout-menu {
  display: block;
}

.over-page {
  visibility: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  background: rgba(0,0,0, .6);
  transform: translate3d(0, 0, 0);
  transition: all .3s ease;

  &.open {
    opacity: .3;
    visibility: visible;
    background: #000;
    z-index: 1040;
    transform: translate3d($menuMobileWidth, 0, 0);
    transition: opacity .3s ease .3s;
  }
}


.panel-menu {
  background-color: #fff;

  .panel-menu-header {
    position: relative;
    width: 256px;
    text-align: center;
    cursor: pointer;

    h1 {
      position: relative;
      display: inline-block;
      padding: ((78px - 36) / 2) 0;
      margin: 0;
      line-height: 1;
      font-size: 36px;

      &::before {
        position: absolute;
        bottom: 12px;
        left: 0;
        width: 100%;
        content: '';
        opacity: 0;
        border-bottom: 2px solid;
        transform: scaleX(0);
        transform-origin: 100% 0%;
        transition: transform 300ms ease 300ms, opacity 300ms;
      }

      &:hover {
        &::before {
          transform-origin: 0% 0%;
          transform: scaleX(1);
          opacity: 1;
          transition: transform 300ms ease 0s, opacity 300ms;
        }
      }
    }
  }

  &-content {
    position: absolute;
    top: 78px;
    left: 0;
    right: 0;
    bottom: 75px;

    > .panel-list {
      -webkit-overflow-scrolling:touch;
      overflow: scroll;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  &-heading {
    display: block;
    margin: 0 35px;
    height: 20px;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: 0.10em;
    text-transform: uppercase;
    overflow: hidden;
    text-align: center;

    &:hover, &:focus, &:active {
      color: $headings-color;
    }

    .icon-back {
      width: 20px;
      height: 24px;
      position: absolute;
      top: 1px;
      left: 4px;
      transform: translateX(-100px);
      opacity: 0;
      font-size: 45px;
      line-height: 20px;
      color: $brand-warning;
      transition: transform .3s ease-in-out, opacity .3s ease .15s;
    }

    &.active {
      .icon-back {
        opacity: 1;
        transform: translateX(0)
      }
    }
  }

  .panel-list {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;

    &__next {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      transform: translate3d(-100%, 0, 0);
      background-color: #fff;
      z-index: 1;
      transition: transform 400ms ease 0s;
    }

    a {
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;
      font-size:  13px;
      padding: 10px 20px;
      border-bottom: 1px solid $border-light;
    }

    li {
      > a {
        transition: transform 400ms ease 0s;
        transform: translate3d(0 ,0 ,0);
      }

      &.next {
        > a {
          transform: translate3d(100%, 0, 0);
        }
      }

      &.next.active > .panel-list__next {
        transform: translate3d(0 ,0 ,0);
      }
    }
  }

  &-footer {
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .social-list {
    justify-content: center;
    text-align: center;
    padding: 10px 0;

    > li {
      > a {
        font-size: 18px;
      }
    }
  }
}
