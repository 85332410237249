/*************************************

    [TABLE OF CONTENTS]

    1.General settings:
      GENERAL
      TYPOGRAPHY
      CUSTOM GRID

    2.Pages, layouts:
      CUSTOM NAVBAR
      SECTION SETTINGS
      INTRO PAGES
      HOME PAGEs
      BLOG LIST, BLOG SINGLE POST
      SIDEBAR
      PAGE SERVICES, PAGE SERVICES-2
      PAGES "SERVICES DETAILS"
      GALLERIES
      ROOMs
      ROOM DETAILS
      ABOUT PAGE
      PAGE FOOTER

    3.Components & modifiers:
      Breadcrumbs for inner pages
      Custom buttons
      Transitions, animations, states
      Decorative elements
      Datepicker for Bootstrap
      CUSTOM FORM
      CUSTOM MODALS
      Contact elements
      Control carousel, pagination, tabs, video, lighbox
      SECTION PANELS
      Mobile menu Slideout.js

    Universal styles

*************************************/

$images: "../img";
$fonts: "../fonts";

$font-size-base: 18;

// Header height all points
$header-height: 78px; // mobiles
$header-height-sm: 128px; // > 767px
$header-height-xl: 110px; // > 1600px

// Sprites
@mixin sprite-image($sprite) {
  background-image: url(#{$sprite-image});
}
$sprite-image: $images + "/sprite.png";

@mixin shadow() {
  box-shadow: 0 3.5rem/$font-size-base 10rem/$font-size-base rgba(0, 0, 0, 0.15);
}

@mixin transitionBase() {
  transition: all 0.4s ease;
}

html {
  font-size: $font-size-base - 3px;
  color: #222;

  @include media('>xs') {
    font-size: $font-size-base - 2px;
  }

  @include media('>lg') {
    font-size: $font-size-base + px;
  }
}

body {
  font-size: 1rem;
  line-height: 1.75;
}

main.page-content {
  position: relative;
  background-color: #fff;
  z-index: 1;
}

// * These selection rule sets have to be separate.
// * Customize the background color to match your design.
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

.form-control::-moz-placeholder {
  text-transform: uppercase;
}

.form-control:-ms-input-placeholder {
  text-transform: uppercase;
}

.form-control::-webkit-input-placeholder {
  text-transform: uppercase;
}


button {
  display: inline-block;
  background-color: transparent;
  border: none;
  outline: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

iframe,
embed {
  border: none;
}

// Remove default fieldset styles.
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

// Allow only vertical resizing of textareas.
textarea {
  resize: vertical;
}


img, svg, .svg {
  vertical-align: middle;
}

a {
  display: inline-block;
  font-size: 14px;
  color: $link-color;
  font-family: 'Lato', sans-serif;
  text-decoration: none;
}

// Settings list
ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}


// page-header reset;
.page-header {
  padding-bottom: 0;
  margin: 0;
  border-bottom: none;
}

label, .label-control {
  display: block;
  margin-bottom: 10px;
  font-family: lato, sans-serif;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  color: $label-color;
}

.form-control {
  &:focus {
    box-shadow: 0 0 0 transparent;
    border-color: $gray-base;
  }
}

.sp-zoom-in {
  overflow: hidden;

  img {
    display: block;
    transform: scale(1);
    transition: 300ms;

    &:hover {
      transform: scale(1.15);
    }
  }
}

img[data-action="zoom"] {
  cursor: url("data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUyLjk2NiA1Mi45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUyLjk2NiA1Mi45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzMnB4Ij4KPGc+Cgk8cGF0aCBkPSJNMjguOTgzLDIwaC02di02YzAtMC41NTItMC40NDgtMS0xLTFzLTEsMC40NDgtMSwxdjZoLTZjLTAuNTUyLDAtMSwwLjQ0OC0xLDFzMC40NDgsMSwxLDFoNnY2YzAsMC41NTIsMC40NDgsMSwxLDEgICBzMS0wLjQ0OCwxLTF2LTZoNmMwLjU1MiwwLDEtMC40NDgsMS0xUzI5LjUzNSwyMCwyOC45ODMsMjB6IiBmaWxsPSIjRkZGRkZGIi8+Cgk8cGF0aCBkPSJNNTEuNzA0LDUxLjI3M0wzNi44NDUsMzUuODJjMy43OS0zLjgwMSw2LjEzOC05LjA0MSw2LjEzOC0xNC44MmMwLTExLjU4LTkuNDItMjEtMjEtMjFzLTIxLDkuNDItMjEsMjFzOS40MiwyMSwyMSwyMSAgIGM1LjA4MywwLDkuNzQ4LTEuODE3LDEzLjM4NC00LjgzMmwxNC44OTUsMTUuNDkxYzAuMTk2LDAuMjA1LDAuNDU4LDAuMzA3LDAuNzIxLDAuMzA3YzAuMjUsMCwwLjQ5OS0wLjA5MywwLjY5My0wLjI3OSAgIEM1Mi4wNzQsNTIuMzA0LDUyLjA4Niw1MS42NzEsNTEuNzA0LDUxLjI3M3ogTTIuOTgzLDIxYzAtMTAuNDc3LDguNTIzLTE5LDE5LTE5czE5LDguNTIzLDE5LDE5cy04LjUyMywxOS0xOSwxOSAgIFMyLjk4MywzMS40NzcsMi45ODMsMjF6IiBmaWxsPSIjRkZGRkZGIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg=="), auto;

  filter: brightness(1);
  transition: filter 300ms, box-shadow 300ms;

  &:hover {
    filter: brightness(0.75);
    box-shadow: 0 3.5rem/$font-size-base 10rem/$font-size-base rgba(0, 0, 0, 0.15);
  }
}
.zoom-img,
.zoom-img-wrap {
  position: relative;
  z-index: 666;
  -webkit-transition: all 300ms;
       -o-transition: all 300ms;
          transition: all 300ms;
}
img.zoom-img {
  cursor: url("data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDUyLjk2NiA1Mi45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUyLjk2NiA1Mi45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzMnB4Ij4KPGc+Cgk8cGF0aCBkPSJNMjguOTgzLDIwaC0xNGMtMC41NTIsMC0xLDAuNDQ4LTEsMXMwLjQ0OCwxLDEsMWgxNGMwLjU1MiwwLDEtMC40NDgsMS0xUzI5LjUzNSwyMCwyOC45ODMsMjB6IiBmaWxsPSIjRkZGRkZGIi8+Cgk8cGF0aCBkPSJNNTEuNzA0LDUxLjI3M0wzNi44NDUsMzUuODJjMy43OS0zLjgwMSw2LjEzOC05LjA0MSw2LjEzOC0xNC44MmMwLTExLjU4LTkuNDItMjEtMjEtMjFzLTIxLDkuNDItMjEsMjFzOS40MiwyMSwyMSwyMSAgIGM1LjA4MywwLDkuNzQ4LTEuODE3LDEzLjM4NC00LjgzMmwxNC44OTUsMTUuNDkxYzAuMTk2LDAuMjA1LDAuNDU4LDAuMzA3LDAuNzIxLDAuMzA3YzAuMjUsMCwwLjQ5OS0wLjA5MywwLjY5My0wLjI3OSAgIEM1Mi4wNzQsNTIuMzA0LDUyLjA4Niw1MS42NzEsNTEuNzA0LDUxLjI3M3ogTTIuOTgzLDIxYzAtMTAuNDc3LDguNTIzLTE5LDE5LTE5czE5LDguNTIzLDE5LDE5cy04LjUyMywxOS0xOSwxOSAgIFMyLjk4MywzMS40NzcsMi45ODMsMjF6IiBmaWxsPSIjRkZGRkZGIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg=="), auto;
}
.zoom-overlay {
  z-index: 420;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  filter: "alpha(opacity=0)";
  opacity: 0;
  -webkit-transition:      opacity 300ms;
       -o-transition:      opacity 300ms;
          transition:      opacity 300ms;
}
.zoom-overlay-open .zoom-overlay {
  filter: "alpha(opacity=100)";
  opacity: 0.8;
}
.zoom-overlay-open,
.zoom-overlay-transitioning {
  cursor: default;
}

.jarallax {
  position: relative;
  z-index: 0;
}
.jarallax > .jarallax-img {
  position: absolute;
  object-fit: cover;
  /* support for plugin https://github.com/bfred-it/object-fit-images */
  font-family: 'object-fit: cover;';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.position-relative {
  position: relative;
  z-index: 1;
}

.b-cover {
  background-size: cover;
}

.b-centered {
  background-position: center center;
}

.rounder-right {
  border-top-right-radius: 8px;
  overflow: hidden;
}

.rounder {
  border-radius: 2px;
}
