/*************************************

    Custom buttons
      ¬ --darker
      ¬ --warn

*************************************/

.btn-wrap {
  margin: 42rem/$font-size-base 0 30rem/$font-size-base;
}

.btn {
  display: inline-block;
  padding: 15px 64px;
  font-family: $lato;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: $ls;
  border-width: $border-radius-base;

  &--darker {
    color: $brand-warning;
    background-color: #333;
    border-color: #333;

    &:hover, &:focus, &:active:focus {
      color: #000;
      background-color: #fff;
      border-color: #000;
      box-shadow: 0 0 0 transparent;
      outline: none;
    }
  }

  &--warn {
    color: $brand-warning;
    border-color: $brand-warning;

    &:hover, &:focus {
      color: #fff;
      background-color: $link-hover-color;
      border-color: $link-hover-color;
      box-shadow: 0 0 0 transparent;
      outline: none;
    }
  }

  &:active:focus {
    outline: none;
    box-shadow: none;
  }

  @include media('<xs') {
    float: none !important;
    display: block;
    width: 100%;
    padding: 15px 3%;
    margin: 0 auto;
    font-size: $fz-upper + px;
    letter-spacing: $ls-xs;
  }
}
