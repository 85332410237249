/*************************************

    SIDEBAR

    = blog-sidebar
      ¬ __subscribe
      ¬ __category
      ¬ __title

    = categories-list
    = sidebar-preview
    = post-stat
    = sidebar-thumb

*************************************/


.blog-sidebar {
  background-color: #fafafa;
  padding: 50px 30px 50 - 36px;
  border: 1px solid $hr-border;
  font-size: 14px;
  line-height: 1.45;
  color: #000;

  .btn {
    width: 100%;
    padding: 15px 0;
  }

  a {
    color: $brand-warning;


    &:hover {
      color: $link-hover-color;
    }

    &:focus {
      color: $gray-base;
    }
  }

  &__subscribe {
    margin-bottom: 50px;
  }

  &__category {
    margin-bottom: 36px;
  }

  p {
    line-height: 1.43em;
    margin-bottom: 1.57em;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 25px;
    font-family: 'Lato', sans-serif;
    line-height: 1;

    &:before {
      @extend %bullet-rotate;
      @extend %bullet-inline;
    }
  }

  @include media('<xs') {
    margin: 0 -15px;
    border-width: 1px 0;
  }
}

.categories-list {
  > li {
    border-bottom: 1px solid $gray-lighter;

    &:last-child {
      border-bottom: 0;
    }

    a {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 11px 0;
      transition: color 300ms;

      span {
        &:last-child {
          margin-left: 10rem/$font-size-base;
          border-bottom: 1px solid transparent;
        }
      }

      &:hover {
        color: $link-hover-color;

        span {
          &:last-child {
            border-bottom: 1px solid;
          }
        }
      }

      &:focus {
        color: $gray-base;
      }
    }
  }
}

.sidebar-post {
  + li {
    padding-top: 15px;
  }

  &:nth-last-child(n+2) {
    border-bottom: 1px solid $gray-lighter;
  }

  &__link {
    display: block;
    width: 100%;
  }

  &__top {
    display: inline-flex;
    max-width: 320px;
  }

  &__thumb {
    flex-shrink: 0;
    height: 48px;
    width: 48px;
    margin-top: 5px;
    margin-right: 12px;
    background-color: $base-bg;
    border-radius: $border-radius-base;

    img {
      filter: brightness(1);
      transition: filter 300ms;

      &:hover {
        filter: brightness(1.1);
      }
    }
  }

  &__stat {
    display: flex;
    justify-content: space-between;
    padding: 9px 0 12px;
    font-weight: 700;

    .comments {
      margin-left: 10rem/$font-size-base;
      white-space: nowrap;

      i {
        margin-right: 4px;
      }
    }
  }
}


.categories-list a,
.blog-tags a {
  font-weight: 700;
}

.blog-sidebar__title,
.categories-list a,
.blog-tags a {
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

@include media('>md', '<lg') {
  .categories-list a,
  .blog-tags a {
    letter-spacing: 0.1em;
  }

  .blog-sidebar__title {
    font-size: 16px;
    letter-spacing: 0.04em;
  }

  .blog-sidebar a {
    font-size: 13px;
  }
}
