/*************************************

    PAGE SERVICES, PAGE SERVICES-2
      = services-menu

    SERVICES
      = slides
        ¬ services-slide
        ¬ __helper

      = pricing
      = pricing-panel

    SERVICES-2
      = services-panels

*************************************/

.page-services main {
  margin-bottom: 80px;
}

.page-services-2 .st-row {
  padding-bottom: 80rem/$font-size-base;
}

.services-slides .st-row {
  padding: 0;
}


/**        = services-slide         **/
.services-slide {
  overflow: hidden;

  &__inner {
    position: relative;

    &::before,
    &::after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &::before {
      opacity: 1;
      background-color: rgba(0,0,0,.7);
      transition: opacity 300ms ease;
      z-index: 1;
    }

    &::after {
      transform: translateY(100%);
      background-color: rgba(#fff, .6);
      z-index: 2;
      transition: transform 400ms;
    }
  }

  &__title {
    position: relative;
    padding: 173px 0;
    margin: 0;
    color: #fff;
    text-align: center;
    opacity: 1;
    transform: scale(1) translateY(0px);
    transition: opacity 400ms 0ms, transform 400ms;
    z-index: 1;

    &::before {
      content: ' ';
      display: block;
      font-size: 9rem/$font-size-base;
      height: 1em;
      width: 1em;
      margin: 0 auto 25rem/$font-size-base;
      background-color: $brand-warning;
      transform: rotate(-45deg);
    }
  }

  &__helper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    text-align: center;
    transform: translateY(100%);
    cursor: pointer;
    z-index: 3;
    transition: transform 500ms ease;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
  &__helper-desc {
    max-width: 650px;
    margin: 0 auto;

    p {
      text-align: left;
    }
  }

  &:hover {
    .services-slide__inner {
      &::before {
        opacity: 0;
      }
      &::after {
        transform: translateY(0px);
      }
    }

    .services-slide__title {
      opacity: 0;
      transform: scale(0.9) translateY(-15px);
      transition: opacity 400ms 150ms, transform 400ms;
    }

    .services-slide__helper {
      transform: translateY(0);
    }
  }
}

.pricing {
  padding-bottom: 20rem/$font-size-base;

  @include media('<lg') {
    text-align: center;
  }
}

/**        = pricing-panel         **/
.pricing-panel {
  position: relative;
  border: 1px solid $hr-border;
  background-color: $bg-lighter;
  padding: 42px 30px 138px;
  margin-bottom: 30rem/$font-size-base;
  border-radius: 2px;

  h2 {
    margin-bottom: 0;
  }

  &__heading {
    margin-bottom: 9px;
  }

  &__heading-rw {

    span {
      display: inline-block;
    }
    .cost {
      @extend %text-brand-lg;
      letter-spacing: normal;
      margin-right: 10px;
    }
    .disabled {
      @extend %text-brand;
      color: $gray-light;
      letter-spacing: normal;
    }
  }


  &__main {

    li {
      padding: 13rem/$font-size-base 0;
      line-height: 30rem/$font-size-base;

      &:nth-last-of-type(n+2) {
        border-bottom: 1px solid #e9e9e9;
      }

      span {
        @extend %text-brand-xs;
        display: block;
      }
    }
  }

  &__footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 34px 30px 50px;
  }

  .flag {
    top: -1px;
    right: -1px;
  }


  &--hero {
    background-color: $gray-dark;
    border-color: $gray-dark;
    color: #fff;

    h2 {
      color: #fff;
    }

    ul > li:nth-last-of-type(n+2) {
      border-bottom: 1px solid #676767;
    }
  }

  @include media('<sm') {
    text-align: center;

    &__footer {
      .btn {
        width: 100%;
      }
    }
  }
}


/**        = services-menu         **/
.services-menu {
  &__inner {
    border: 1px solid $hr-border;
    background-color: #fff;
    padding: 42px 30px 24px;
    border-radius: 2px;
  }

  &__item {
    margin-bottom: 18px;
  }

  &__list {
    display: table;
    width: 100%;

    li {
      display: table-row;
    }

    li span {
      display: table-cell;
    }

    li span:last-child {
      text-align: right;
      @extend %text-brand-xs;
    }
  }

  @include media ('<xs') {
    text-align: center;

    &__list {
      display: block;

      li {
        margin-bottom: 18px;
      }

      li,
      li span {
        display: block;
      }

      li span:last-child {
        text-align: center;
      }
    }
  }
}

/**        = services-panels          **/
.service-panel {
  position: relative;
  overflow: hidden;

  &--gray {
    background-color: $bg-lighter;
  }

  &__cover {
    overflow: hidden;

    img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: all 500ms;
      transform: scale(1);
    }

    &.pull-right {
      left: auto;
      right: 0;
    }
  }

  &__link {
    display: block;
    height: 100%;
    width: 100%;
    overflow: hidden;

    &:hover {
      img {
        transform: scale(1.15);
        filter: brightness(0.75);
      }
    }
  }

  &__card {
    position: relative;
    padding: 50px 30px;
    background-color: #fff;

    &::before {
      content: '';
      display: block;
      position: absolute;
    }

    &::before {
      left: 50%;
      right: auto;
      top: -30rem/$font-size-base;
      margin-top: 0;
      margin-left: -30rem/$font-size-base;
      @include triangle(up, #fff, 60rem/$font-size-base, 30rem/$font-size-base);
    }

    &.pull-right {
      background-color: $bg-lighter;

      &::before {
        @include triangle(up, $bg-lighter, 60rem/$font-size-base, 30rem/$font-size-base);
      }
    }
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20rem/$font-size-base;

    .title {
      margin-right: 15px;
      line-height: 1.25;
      width: 100%;
    }

    .cost {
      @extend %text-brand-xs;
      line-height: inherit;
      white-space: nowrap;
    }

    > * {
      margin-bottom: 10px;
    }
  }

  @include media('>xs') {
    &__top {
      .title {
        font-size: 30rem/$font-size-base;
      }
    }
  }

  @include media('>sm') {
    &__cover {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      min-width: 500px;
      padding: 0;
    }

    &__card {
      padding: 50px;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: auto;
        right: -30rem/$font-size-base;
        top: 50%;
        margin-top: -30px;
        @include triangle(right, #fff, 30rem/$font-size-base, 60rem/$font-size-base);
      }

      &.pull-right {
        &::before {
          right: auto;
          left: 0px;
          @include triangle(left, $bg-lighter, 30rem/$font-size-base, 60rem/$font-size-base);
        }
      }
    }
  }

  @include media('>lg') {
    &__card {
      padding: 80px 80px 50px 15px;

      &.pull-right {
        padding: 80px 15px 50px 80px;
      }
    }

    &__top {
      .title {
        width: auto;
      }
    }
  }
}
