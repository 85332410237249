/*************************************

    ROOMs
    = room-list
      ¬ room-banner
      ¬ room-hero

*************************************/

.room-list {
  &__item {
    margin-bottom: 30px;
  }
}

.room-banner {
  position: relative;
  height: 370px;
  padding-bottom: 84px;
  overflow: hidden;

  &__img {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    height: 370px;
    width: 556px;
    margin-top: -370/2 + px;
    margin-left: -556/2 + px;

    img {
      width: 100%;
    }
  }

  &__footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 1.1rem;
    border-top: 400px solid transparent;
    @include transitionBase();

    .row {
      justify-content: space-between;
      align-items: center;
      padding: 20px 50/555*100%;
      margin: 0;
      background-color: rgba(#000, .7);

      > * {
        width: auto;
        position: relative;
        z-index: 2;

        &:last-child {
          margin-left: auto;
        }
      }
    }

    @include media('>sm') {
      font-size: 1.25rem;
    }

    @include media('>md') {
      font-size: 1.5rem;
    }

    @include media('>lg') {
      font-size: 1.8rem;
    }
  }

  &__title {
    font-family: $playfair;
    color: #fff;
    font-weight: normal;
    font-size: inherit;
    letter-spacing: normal;
    text-transform: none;
  }

  &__cost {
    @extend %text-brand-lg;
    margin-left: 10px;
    font-size: inherit;
    letter-spacing: normal;
  }

  &__more {
    position: relative;
    padding: 44px 50/555*100% 0;
    color: #fff;
    z-index: 2;
    opacity: 0;
    transition: .4s;

    > * {
      position: relative;
    }
  }

  &.active {
    .room-banner__footer {
      border-color: rgba(#000, .7);
    }

    .room-banner__more {
      opacity: 1;
    }
  }
}


.room-hero {
  color: #fff;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__main {
    max-width: 555px;
    margin: 0 auto;
  }

  &__top {
    @extend %text-brand;
  }

  h1 {
    color: inherit;
    margin-top: 5rem/$font-size-base;
    margin-bottom: 30rem/$font-size-base;
  }

  p {
    margin-bottom: 32rem/$font-size-base;
  }

  .btn {
    max-width: 245px;
  }
}
