/*************************************

    CUSTOM NAVBAR

    = page-header
    = navbar
      ¬ navbar-fixed  - page-header-fixed modifier
      ¬ menu-trigger
      ¬ brand
      ¬ logo
      ¬ social-list
      ¬ reservation
      ¬ dropdown-menu

    [states and dropdown-menu]
    [affix - transform menu]

*************************************/

.navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: $header-height;
  margin: 0;
  text-transform: uppercase;
  border: none;
  border-radius: 0;
  z-index: $zindex-navbar-fixed;
  box-shadow: 0 3.5rem/$font-size-base 10rem/$font-size-base rgba(0,0,0,.15)!important;

  a:focus {
    color: $link-hover-color;
  }

  @include media('>sm') {
    height: auto;
  }

  &.affix {
    position: fixed;

    @include media('>xl') {
      top: 0 !important;

      .navbar-menu {
        height: 64px;

        > li > a {
          font-size: 14px;
        }
      }
    }

    @include media('<sm') {
      top: 0 !important;
      height: 60px;
    }
  }

  &__topline {
    position: relative;
    display: flex;
    top: 0;
    height: 100%;
    border-bottom: 1px solid rgba(#000, .2);

    @include media('>xl') {
      position: absolute;
      width: 100%;
      height: 100%;
      border-bottom-color: rgba(#000, 0);

      .navbar__toggle-reservation {
        display: none !important;
      }
    }

    @include media('>sm') {
      .navbar__toggle-reservation {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &-header {
    &.container {
      &:before, &:after {
        display: none;
      }

      display: flex;
      justify-content: space-between;

      > * {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      @include media('>xl') {
        width: 100%;
      }

      @include media('<xl') {
        padding: 15px;
        align-items: center;
      }

      @include media('<sm') {
        width: 100%;
      }
    }
  }

  &-brand {
    .navbar > .container & {
      margin-left: 0;
    }
    display: block;
    height: auto;
    padding: 0;
    border-right: 1px solid transparent;
    z-index: 1000;

    .logo {
      height: 47px;
      width: 47px;
      display: inline-block;
      overflow: hidden;
    }

    @include media('<sm') {
      .logo {
        height: 36px;
        width: 36px;
      }
    }
  }

  .social-list {
    padding-left: 25px;
    border-left: 1px solid transparent;
  }

  &__menu {
    > .container {
      &:before,
      &:after {
        display: none;
      }

      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1;
    }

    @include media('<xl') {
      .navbar__toggle-reservation {
        display: none !important;
      }
    }
  }

  &__toggle-reservation {
    display: inline-flex;
    flex-shrink: 0;
    padding: 0;
    letter-spacing: $ls;
    color: $brand-warning;

    display: inline-block;
    font-size: 14px;
    text-decoration: none;

    .fa {
      display: inline-block;
      font-size: 1.11em;
      padding-right: 10px;
      letter-spacing: normal;
    }
  }
}


/**        [states and dropdown-menu]         **/
.navbar-menu {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 115px;
  transition: 0.30s ease;

  a {
    letter-spacing: $ls;
  }

  .dropdown-menu {
    float: none!important;
    min-width: 165px;
    display: block;
    left: 0;
    right: auto;
    top: 100%;
    padding: 0;
    opacity: 0;
    border: none;
    @include shadow();
    visibility: hidden;
    transform: translateY(15px);

    a {
      display: flex;
      justify-content: space-between;
      padding: 10px 15px;
      font-size: 14px;
      border-bottom: 1px solid $hr-border;
      font-weight: 700;
      line-height: 1;

      *:nth-child(2) {
        margin-left: 7.5rem/$font-size-base;
      }

      &:hover {
        color: $gray-base!important;
      }
    }

    li {
      &:last-child > a {
        border-bottom-color: transparent;
      }
    }
  }

  > li {
    position: relative;
    display: flex;
    margin-right: 50/980*100%;

    &:last-child {
      margin-right: 0;
    }

    > a {
      display: inline-flex;
      align-items: center;
      padding: 5px 0;
      color: $link-color;
      box-shadow: inset 0 0 0 $brand-warning;
      @include transitionBase();

      .fa-caret-down {
        font-size: 11px;
        margin-left: 4px;
      }

      @include media('<xl') {
        padding: 15px 0;
      }
    }

    > .dropdown-menu {
      transition: transform 0.4s 0s, opacity 0.4s ease 0s, visibility 0s 0.4s;

      &.pull-right {
        left: -100%;
      }
    }

    &:hover {
      > a {
        box-shadow: inset 0 -4px 0 $brand-warning;
      }
      > .dropdown-menu {
        z-index: 1040;
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        transition: transform 0.4s 0s, opacity 0.4s ease 0s, visibility 0s 0s;
      }
    }
  }

  > li li {
    position: relative;

    .dropdown-menu {
      left: 100%;
      right: auto;
      top: 0;
      margin: 0;
      transform: translateX(10px);
      border-top-left-radius: 0;

      > li:first-child > a {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: -4px;
          width: 4px;
          background-color: $brand-warning;
          transform-origin: 100% 0;
          transform: scaleX(0);
          transition: transform 0.12s 0.15s linear;
        }
      }

      &.pull-right {
        left: 0;
        transform: translateX(calc(-100% - 10px));
        border-top-left-radius: 2px;
        border-top-right-radius: 0;

        > li:first-child > a {
          &:before {
            left: calc(100%);
            transform-origin: 0 0;
          }
        }
      }
    }

    &:hover {
      > .dropdown-menu {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
        transition: transform 0.2s 0s;
        z-index: 1040;

        &.pull-right {
          transform: translateX(calc(-100%));
        }

        > li:first-child > a {
          &:before {
            transform: scaleX(1);
          }
        }
      }
    }
  }

  @include media('<xl') {
    height: auto;
    min-height: auto;
    justify-content: center;
  }
}

@include media('>xl') {
  .navbar-header {
    padding: 0 50px;
    width: auto;
    margin: 0;
  }

  .navbar-nav a  {
    font-size: 18px;
  }

  .navbar-brand {
    padding-right: 50px;
    border-right: 1px solid $hr-border;
  }

  .navbar .social-list {
    padding-left: 50px;
    border-left: 1px solid $hr-border;
  }
}
