/*************************************

    GALLERIES

    = grid-sizer
    = gallery
    = gallery-filter
      ¬ overlay - hover state

    = blueimp-gallery

*************************************/

/**         masonry settings column        **/
.grid-sizer {
  padding: 0;
  margin: 0;
  min-height: 0;
  float: none;
}

.gallery {

  .row {
    margin: 0 -4px;
  }

  &--room {
    margin-bottom: 72rem/$font-size-base;
  }

  &__item {
    padding: 0 4px;
    margin-bottom: 8px;
  }

  &__img-wrap {
    position: relative;
    display: block;
    z-index: 2;

    img {
      width: 100%;
    }
  }

  .btn-wrap {
    text-align: center;
  }
}


.gallery-filter {
  background-color: $gray-dark;
  text-align: center;
  padding: 13rem/$font-size-base  0;
  @include clearfix();

  &__item {
    display: inline-block;
    padding: 0 25rem/$font-size-base;

    > button {
      display: block;
      @extend %text-brand;
      @extend %hover-border;
      color: #fff;
    }
  }
}

/**        ¬ overlay         **/
.gallery,
.hm-gallery-2,
.post-gallery {

  &__img-wrap {
    position: relative;
    display: block;

    .overlay {
      opacity: 0;
      transition: .4s;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #8c8a88;
        background: url($images + '/cross.svg') no-repeat 50% / 80px auto;
      }
    }

    &:hover .overlay {
      opacity: 1;
    }
  }
}

