/*************************************

    TYPOGRAPHY

*************************************/

$fz: 1em;
$fz-upper: 12;
$fz-sm: 14px;

// letter-spacing
$ls: 0.2em;
$ls-xs: 0.08em;

// fonts
$font-family: $font-family-serif;
$playfair: "Playfair Display", Georgia, "Times New Roman", Times, serif;
$lato: Lato, "Helvetica Neue", Helvetica, Arial, sans-serif;


h1, .h1, h2, h3, .h3, h5, h6 {
  color: $gray-base;

  small,
  .small {
    font-weight: normal;
    line-height: 1;
    color: $gray-light;
  }
}

.small {
  font-family: $playfair;
  font-size: $fz;
  line-height: 1;
}

h1, .h1 {
  font-size: 48rem/$font-size-base;
  margin-bottom: 30rem/$font-size-base;
}

h1, .h1, h3, .h3 {
  font-weight: 700;
}

h2, .h2 {
  font-size: 30rem/$font-size-base;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 1.75rem;
}

h3, .h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: $font-family;
}

h4, .h4 {
  font-family: $lato;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.section-header {
  margin-bottom: 30rem/$font-size-base;
  a {
    font-size: 1em;
  }

  h2 {
    margin: 0;
  }
}

label,
.label-control {
  letter-spacing: $ls;
}

p {
  margin-bottom: 1rem;
}

p a {
  font-family: inherit;
}

.list-type ul {
  margin-bottom: 1rem;
  text-align: left;
}

mark {
  padding: 0;
  color: #fff;
  background-color: $brand-warning;
}

figcaption {
  font-family: $font-family;
}

.list-type {
  position: relative;
  padding-left: 17rem/$font-size-base;
  margin-left: -17px;

  li:before {
    content: ' ';
    position: absolute;
    left: 0;
    display: block;
    height: 1em;
    width: 1em;
    margin-top: 11rem/$font-size-base;
    line-height: 1;
    font-size: 7rem/$font-size-base;
    background-color: $brand-warning;
    transform: rotate(-45deg);
  }
}

.link-more {
  font-family: $font-family;
  font-size: 18rem/$font-size-base;
  vertical-align: middle;
  color: $brand-warning;
  padding-bottom: 5px;
  border-bottom: 1px solid $brand-warning;

  .fa {
    text-align: right;
    font-size: 17rem/$font-size-base;
    margin-left: 8px;
    vertical-align: bottom;
  }
}

.btn-link {
  &:hover {
    text-decoration: none;

    &::after {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  &::after {
    display: block;
    content: '';
    transform: translateY(4px);
    border-bottom: 1px solid;
    transition: 300ms;
    opacity: 0;
  }
}

// blockquote
//
blockquote {
  padding: 0;
  position: relative;
  font-style: italic;
  font-size: 22rem/$font-size-base;
  padding-left: 48rem/$font-size-base;
  margin-left: -48px;
  border: none;
  text-align: left;
  color: $gray-base;

  p {
    margin-bottom: 0;
    font-size: 1em;

    &:before {
      content: '“';
      font-family: $playfair;
      position: absolute;
      font-size: 105rem/$font-size-base;
      left: 0;
      top: -38rem/$font-size-base;
      line-height: 1em;
      font-style: normal;
      display: inline-block;
      color: $brand-warning;
    }
  }

  footer {
    text-align: right;
    cite {
      font-style: normal;
    }
    &:before {
      content: '\00A0 \2014';
    }
  }

  @include media('<380px') {
    font-size: 1em;
  }
}

@include media('<md') {
  blockquote,
  .list-type {
    margin-left: 0;
  }
}

h1.title-hidden {
  display: none;
}

// Contextual colors
.text-muted {
  color: $text-muted;
}

@include text-emphasis-variant('.text-warning', $brand-warning);

// Template classess
//
%text-brand {
  font-family: $lato;
  color: $brand-warning;
  text-transform: uppercase;
  letter-spacing: $ls;
  font-size: 1em;
}

%text-brand-xs {
  font-family: $lato;
  color: $brand-warning;
  text-transform: uppercase;
  font-size: 14rem/$font-size-base;
  font-weight: 700;
  letter-spacing: $ls;
}

%text-brand-lg {
  font-family: $lato;
  color: $brand-warning;
  text-transform: uppercase;
  letter-spacing: $ls;
  font-size: 30rem/$font-size-base;
}

@include media('<380px') {
  %text-brand,
  %text-brand-xs,
  %text-brand-lg {
    letter-spacing: $ls-xs;
  }
}

.text-gray {
  color: $gray-light!important;
}
